<template>
  <div
    :class="{ box, padded }"
    class="wrapper"
  >
    <step-heading
      :step="step"
      :title="title || $t('services.carousel.title')"
    />
    <swiper :options="swiperOptions">
      <swiper-slide
        v-for="(slot, index) in $slots"
        :key="index"
        :class="type"
        class="slide"
      >
        <slot :name="index" />
      </swiper-slide>
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        slot="button-next"
        class="swiper-button-next"
      />
    </swiper>
  </div>
</template>

<script>
import StepHeading from '@/views/pages/AIServices/components/StepHeading/Index.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: 'Carousel',
  components: {
    StepHeading,
    Swiper,
    SwiperSlide,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: '',
    },
    box: {
      type: Boolean,
      default: true,
    },
    padded: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'video'].indexOf(value) !== -1,
    },
  },
  computed: {
    swiperOptions() {
      return {
        slidesPerView: 'auto',
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        ...this.options,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$slide-size: 1rem;

.wrapper {
  position: relative;

  &.box {
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $slide-size;
      height: $slide-size;
    }
  }

  &.padded {
    .swiper-slide {
      padding: 0 0.06rem 0.12rem 0.06rem;
    }
  }
}

.swiper {
  &-container {
    margin-top: 0.21rem;
  }

  &-slide {
    overflow: hidden;
    border-radius: 0.04rem;
  }

  /deep/ &-slide.video .example {
    &::after {
      content: url('~@/assets/images/icons/play-video.svg');
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }

    &.swiper-selected::after {
      content: url('~@/assets/images/icons/select.svg');
    }
  }

  /deep/ &-selected::after {
    content: url('~@/assets/images/icons/select.svg');
    background: rgba(58, 113, 255, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-button-prev,
  &-button-next {
    width: 0.38rem;
    height: 0.38rem;
    border-radius: 50%;
    box-shadow: 0 0.02rem 0.1rem rgba(180, 185, 193, 0.29);
    background-color: white;

    &:after {
      color: $text-blue;
      font-size: 0.14rem;
      font-weight: bold;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }
}
</style>
